import { selfAssesmentTypes } from "./selfAssesmentTypes"

const initSelfAssesment = {
    modalCommonsOpen: false,
    modalRanksOpen: false,
    modalFunctionalsOpen: false,
    modalCommitmentsOpen: false,
}

export const selfAssesmentReducer = (state = initSelfAssesment, action) => {
    switch (action.type) {
        case selfAssesmentTypes.openMCommons:
            return {
                ...state,
                modalCommonsOpen: true
            }
        case selfAssesmentTypes.closeMCommons:
            return {
                ...state,
                modalCommonsOpen: false
            }
        case selfAssesmentTypes.openMRanks:
            return {
                ...state,
                modalRanksOpen: true
            }
        case selfAssesmentTypes.closeMRanks:
            return {
                ...state,
                modalRanksOpen: false
            }
            case selfAssesmentTypes.openMFunctionals:
                return {
                    ...state,
                    modalFunctionalsOpen: true
                }
            case selfAssesmentTypes.closeMFunctionals:
                return {
                    ...state,
                    modalFunctionalsOpen: false
                }
            case selfAssesmentTypes.openMCommitments:
                return {
                    ...state,
                    modalCommitmentsOpen: true
                }
            case selfAssesmentTypes.closeMCommitments:
                return {
                    ...state,
                    modalCommitmentsOpen: false
                }
        default:
            return state;
    }

}