import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { areaStartLoading } from '../areas/areaActions';
import { MainButton } from '../buttons/main/MainButton'
import { competencieStartLoading } from '../competencies/competencieActions';
import { gradeStartLoading } from '../grades/gradeActions';
import { levelStartLoading } from '../levels/levelActions';
import { positionStartLoading } from '../positions/positionActions';
import { teamStartLoading } from '../teams/teamActions';

export const DashboardAdmin = ({router, handleClick}) => {
    const menuAdmin = [
        {   name: "grados",   id : "grades",    value: "Grados" },
        {   name: "niveles",   id : "levels",    value: "Niveles" }, 
        {   name: "areas",   id : "areas",    value: "Areas" },
        {   name: "cargos",   id : "positions",    value: "Cargos" },
        {   name: "equipo",   id : "team",    value: "Equipo" },
        {   name: "evaluadores",   id : "evaluators",    value: "Evaluadores" },
        {   name: "reportes",   id : "reports",    value: "Reportes" },
    ];
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(gradeStartLoading());
        dispatch(levelStartLoading());
        dispatch(competencieStartLoading());
        dispatch(positionStartLoading());
        dispatch(areaStartLoading());
        dispatch(teamStartLoading());
    }, [dispatch]);
    return (
            <div className="row">
                {
                    menuAdmin.map(menu => {
                        return(
                            <div key={menu.id} className="col-md-4 col-xs-6 p-3">  
                            <MainButton
                                key={menu.id}
                                nameButton={menu.name}
                                idButton={menu.id}
                                valueButton={menu.value}
                                handleClick={handleClick}
                                router={router}
                            />
                        </div>
                        );
                    })
                }
            </div>
    );
}
