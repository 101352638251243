import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { HelpButton } from '../buttons/help/HelpButton';
import { MainButton } from '../buttons/main/MainButton'
import { generalStartLoading, generalStepsLoading } from '../general/generalActions';
import { Loading } from '../ui/loading/Loading';

export const DashboardCollaborator = ({router, handleClick}) => {
    let menuCollaborator = [];
    const dispatch = useDispatch();
    const {id} = useSelector(state => state.auth);
    const {loading, steps, loadingSteps, collaborator} = useSelector(state => state.general);
    const collab = collaborator[0];
    let concert = false;
    let evaluation = false; 
    
    if(loadingSteps){
        dispatch(generalStartLoading(id));
        if(collaborator.length > 0)
            dispatch(generalStepsLoading(collab.id, router)); 
    }
     
    if(steps){    
        if(steps[0].step === null || steps === "null"){
                menuCollaborator = [
                ...menuCollaborator,
                    {   name: "dgenerales",   id : "general",    value: "Datos Generales" }
                ];
        } else if (steps[0].step == 1) {
                menuCollaborator = [
                    ...menuCollaborator,
                    {   name: "compromisos",   id : "commitments",    value: "Compromisos" }
                ];
        }else if (steps[0].step == 2) {
            menuCollaborator = [
                ...menuCollaborator,
                {   name: "capacitacion",   id : "training",    value: "Capacitación" }
            ];
        }else if (steps[0].step == 3) {
                concert = true;
        }else if (steps[0].step == 4) {
                menuCollaborator = [
                    ...menuCollaborator,
                        {   name: "autoevaluacion",   id : "assesment",    value: "Autoevaluación" }
                    ];
        }else if (steps[0].step == 5) {
                evaluation = true;
        }
    }
    if(loading){
        return <Loading/>;
    }

   

    return (
            <div className='container'>
            <div className="row">
                {
                    menuCollaborator.map(menu => {
                        return(
                            <div key={menu.id} className="col-md-4 col-xs-6 p-3">
                            <MainButton
                                key={menu.id}
                                nameButton={menu.name}
                                idButton={menu.id}
                                valueButton={menu.value}
                                handleClick={handleClick}
                                router={router}
                            />
                        </div>
                        );
                    })
                }
                {
                    (concert === true)
                    ? <div className='alert-danger text-center m-5 p-5'><h4>Te invitamos a contactar a tu evaluador para hacer la Concertación.</h4></div>
                    : <></>
                } 
                {
                    (evaluation === true)
                    ? <div className='alert-danger text-center m-5 p-5'><h4>Te invitamos a contactar a tu evaluador para hacer la Evaluación.</h4></div>
                    : <></>
                }       
            </div>
            <HelpButton type='video' path='https://www.youtube.com/embed/UOBOPERkZhY'/>
            </div>
    );
}
