import React from 'react'
import { useDispatch } from 'react-redux'

import { BackButton } from '../buttons/back/BackButton'
import { MainButton } from '../buttons/main/MainButton'
import { NavBar } from '../navbar/NavBar'

import { Subtitle } from '../ui/subtitle/Subtitle'
import { openMSelfCommitments, openMSelfCommons, openMSelfFunctionals, openMSelfRanks } from './selfAssesmentActions'
import { SelfCommitmentsModal } from './SelfCommitmentsModal'
import { SelfCommonsModal } from './SelfCommonsModal'
import { SelfFunctionalsModal } from './SelfFunctionalsModal'
import { SelfRanksModal } from './SelfRanksModal'

export const SelfAssesmentScreen = () => {
  const menuSelf = [
    {name: "comunes", id: "commons", value: "Comunes", handleClick: () => {dispatch(openMSelfCommons())}},
    {name: "niveles", id: "ranks", value: "Nivel Jerarquico", handleClick: () => {dispatch(openMSelfRanks())}},
    {name: "funcionales", id: "functionals", value: "Funcionales", handleClick: () => {dispatch(openMSelfFunctionals())}},
    {name: "competencias", id: "commitments", value: "Desempeño", handleClick: () => {dispatch(openMSelfCommitments())}}
  ]
  const dispatch = useDispatch();
  return (
    <div>
      <NavBar />
      <div className="container">
        <Subtitle value="Autoevaluación" />
        <div className="row">
                {
                    menuSelf.map(menu => {
                        return(
                            <div key={menu.id} className="col-md-4 col-xs-6 p-3">  
                            <MainButton
                                key={menu.id}
                                nameButton={menu.name}
                                idButton={menu.id}
                                valueButton={menu.value}
                                handleClick={menu.handleClick}
                            />
                        </div>
                        );
                    })
                }
            </div>
      </div>
      <BackButton path="/main" />
      <SelfCommonsModal/>
      <SelfRanksModal/>
      <SelfFunctionalsModal/>
      <SelfCommitmentsModal/>
    </div>
  )
}
