import React from 'react'
import Modal from 'react-modal/lib/components/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { Subtitle } from '../ui/subtitle/Subtitle';
import { closeMSelfCommons } from './selfAssesmentActions';

const customStyles = {
    content: {
        top: 'auto',
        left: 'auto',
        right: 'auto',
        bottom: 'auto',
        marginRight: '0%',
        Transform: 'translate(-50%, -50%)',

    }
}
Modal.setAppElement('#root');
const initial = {
    name: ''
}
export const SelfCommonsModal = () => {
    const {modalCommonsOpen} = useSelector(state => state.self)
    const dispatch = useDispatch();

    const closeModal = () => {
        dispatch(closeMSelfCommons());
    }
  return (
    < Modal
        isOpen = {modalCommonsOpen}
        onRequestClose = { closeModal }
        closeTimeoutMS = { 200}
        style = { customStyles }
        className = 'modal'
        overlayClassName = 'modal-fondo'
    >
        <Subtitle value="Autoevaluación Comunes" close={true} fclose={closeModal} />
    </Modal> 
  )
}
